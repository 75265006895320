import packpAbi from '../config/abi/pack.json'

import Web3 from 'web3'
import BigNumber from 'bignumber.js'

//获取支付币种信息
function getPayToken(myaddress) {
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.web3.currentProvider);
        console.log(myaddress);
        let crossingOkContractInstance = new web3.eth.Contract(packpAbi, '0x7fc233F0c46517b9bdb9e81f7DEBa46150E46E0f');
        crossingOkContractInstance.methods.payToken().call().then(res => {
            resolve(res)
        })
    })
}
 //你这个地方不该？？？
function pledge(myaddress,amount){
    return new Promise(async function (resolve, reject) {
        let web3 = new Web3(window.web3.currentProvider);

        let crossingOkContractInstance = new web3.eth.Contract(packpAbi, '0x7fc233F0c46517b9bdb9e81f7DEBa46150E46E0f');
        const gas = await crossingOkContractInstance.methods.pledge(amount).estimateGas({ from: myaddress });
        let hash = ''
        crossingOkContractInstance.methods.pledge(amount).send({ from: myaddress,gas: gas,gasPrice :5000000000 })
            .on('transactionHash', res => {
                hash = res
            }).on('confirmation', res => {
                resolve(hash)
            }).on('error', res => {
                reject(res)
            })
    })
}

export default {
    getPayToken,
    pledge
}
