<template>
  <div>
    <Header></Header>

    <div class="home_page">
      <van-notice-bar color="#fff" left-icon="volume-o" :text="indexinfo.latest_announcement" background="#203289" @click="toNotice" />
      <div class="banner">
        <!-- <div>
          <span>最牛的团队</span>
        </div>
        <div style="margin-top: 10px">
          <span>打造最好的平台</span>
        </div> -->
        <!-- <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item class="swipe-item">
            <img src="@/assets/image/banner.png" alt="">
          </van-swipe-item>
          <van-swipe-item class="swipe-item">2</van-swipe-item>
        </van-swipe> -->
        <van-swipe :autoplay="3000" class="my-swipe">
          <van-swipe-item class="swipe-item" v-for="(image, index) in images" :key="index">
            <img :src="image" />
            <div class="item_text">
              <div>
                <span>最牛的团队</span>
              </div>
              <div style="margin-top: 10px">
                <span>打造最好的平台</span>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="content">
        <div class="slogan">
          <div class="welcome">
            <span class="welcome_top">Welcome</span>
            <span>平台宣传标语</span>
          </div>
          <div @click="jump" class="lease">
            <span>我要租售算力</span>
          </div>
        </div>

        <div class="total_box" v-if="userinfo">
          <div class="total_universal" style="margin-bottom: 10px" @click="toAssets" >
            <span>当前算力总额</span>
            <span class="universal_amount" style="font-size: 20px">{{ Math.floor(userinfo.power * 100) / 100 || 0 }}</span>
          </div>
          <div class="total_list">
            <div class="total_universal" @click="toPage(1)">
              <span>平台币总额</span>
              <span class="universal_amount">{{ Math.floor(userinfo.token * 100) / 100 || 0 }}</span>
            </div>
            <div class="total_universal" @click="toPage(2)">
              <span>USDT余额</span>
              <span class="universal_amount">{{ Math.floor(userinfo.usdt * 100) / 100 || 0 }}</span>
            </div>
            <div class="total_universal" @click="toTeam">
              <span>团队总算力</span>
              <span class="universal_amount">{{ Math.floor(userinfo.team_power * 100) / 100 || 0 }}</span>
            </div>
          </div>
        </div>
        <div class="aleo">
          <img src="@/assets/image/aleo.jpg" class="aleo" />
        </div>
        <div class="caption" @click="toIntroduce">
          <span>平台介绍</span>
          <img src="@/assets/image/right_arrow.png" class="right_arrow" />
        </div>
        <div class="introduce">
          <div class="introduce_top">
            <img src="@/assets/image/introduce_img.png" class="introduce_img" />
            <div style="padding-top: 6px">
              <!-- <div class="intro">平台或公司相关介绍</div> -->
              <div>Aleo是第一个使用零知识证明解决隐私问题、同时保证可编程特性的公链。</div>
            </div>
          </div>
          <div style="text-indent: 2em">
            <p style="margin: 3px 0">Aleo提供的隐私保护包括隐藏参与者、金额、智能合约等交互细节，而且Aleo还将智能合约执行转移到链下，支持各种Dapp，保证其可拓展性（每秒数千笔交易)。</p>
            <p style="margin: 5px 0">
              ALEO于2016年形成初步构思，2019年成立实验室、于2021年4月20日由A16Z领投2800万美金，2022年B轮融资有三星、A16Z、老虎基金、coinbase、软银意愿等19家机构高达2亿美金注入。是零知识证明行业有史以来最大的一笔投资，ALEO现在估值已到达14.5亿美金。ALEO是采取学分制的，通过我们布置服务器算力工作获得区块学分奖励，学分是ALEO运行的唯一燃料，测试有奖励，主网算力产出，现在采取5个学分兑换1个ALEO的TOKEN.我们错过了2009年涨了1380万倍的比特，错过了14年涨了15580倍的以太，错过了21年涨了8705倍的狗狗，今天ALEO是整个区块链ZK领域和隐私保护的赛道龙头，主网即将上线，属于我们的机会已然来临。。。。。
            </p>
          </div>
        </div>

        <div class="caption">
          <span>合作伙伴</span>
        </div>
        <div class="currency_list">
          <div v-for="(item, index) of currencyList" :key="index" class="currency_li">
            <img :src="item" class="li_img" />
          </div>
        </div>

        <div class="caption">
          <span>联系我们</span>
        </div>
        <div class="relation">
          <div class="relation_li" @click="toLink(indexinfo.twitter_link)">
            <img src="@/assets/image/twitter_logo.png" class="logo_img" />
            <img src="@/assets/image/twitter.png" class="img" />
          </div>
          <div class="relation_li" @click="toLink(indexinfo.telegram_link)">
            <img src="@/assets/image/telegram_logo.png" class="logo_img" />
            <img src="@/assets/image/telegram.png" class="img" />
          </div>
        </div>
      </div>

      <!-- 绑定弹窗 -->
      <van-popup v-model="show" round style="width: 80%" :close-on-click-overlay="false">
        <div class="bind_box">
          <div style="font-weight: bold">绑定邀请地址</div>
          <div class="address_field">
            <van-field v-model="inviteCode" placeholder="请输入上级钱包地址" />
          </div>
          <div class="bind_button" @click="register">
            <span>绑定</span>
          </div>
        </div>
      </van-popup>
      <van-notify v-model="bindShow" type="danger">
        <van-icon name="bell" style="margin-right: 4px" />
        <span>请输入上级钱包地址</span>
      </van-notify>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';

import { mapState } from 'vuex';
import { homeApi } from '@/api/index';
import Clipboard from 'clipboard';
import Swipers from '../../components/BaseSwiper.vue';
import SynthesisPoup from '../../components/synthesisPoup.vue';
import * as echarts from 'echarts';
import Web3 from 'web3';
import { Swipe, SwipeItem, Notify } from 'vant';
export default {
  data() {
    return {
      currencyList: [
        require('@/assets/image/currency/currency1.png'),
        require('@/assets/image/currency/currency2.png'),
        require('@/assets/image/currency/currency3.png'),
        require('@/assets/image/currency/currency4.png'),
        require('@/assets/image/currency/currency5.png'),
        require('@/assets/image/currency/currency6.png'),
      ],
      show: false, //弹窗状态
      address: '', //上级地址
      noticeText: '', //公告
      indexinfo: {
        // power: undefined,
        // token: undefined,
        // usdt: undefined,
        // team_poser: undefined,
        twitter_link: undefined,
        telegram_link: undefined,
        latest_announcement: undefined,
      },
      inviteCode: undefined,
      userinfo: {
        power: undefined,
        token: undefined,
        usdt: undefined,
        team_poser: undefined,
      },
      bindShow: false,
      images: [require('@/assets/image/banner.png'), require('@/assets/image/banner1.jpg')],
    };
  },
  created() {
    this.inviteCode = this.$route.query.inviteCode;
    if (this.inviteCode) {
      localStorage.setItem('inviteCode', this.inviteCode);
    }
    this.userinfo = JSON.parse(localStorage.getItem('userinfo'));
    // if (this.account) {
    //   this.init();
    // }
  },
  mounted() {
    this.inviteCode = this.$route.query.inviteCode;
    if (this.inviteCode) {
      localStorage.setItem('inviteCode', this.inviteCode);
    }
    // if (this.account) {
    //   this.init();
    //   // this.bind();
    // }

    //注册一个事件总线  on
    this.$bus.$on('updateUserinfo', data => {
      console.log('走了事件总线', data);
      if (data == 'update') {
        this.init();
      }
      // 刷新用户信息
    });
  },
  methods: {
    init() {
      // this.getAnnouncement();
      this.getIndexInfo();
      this.updateInfo();
      // if (this.userinfo.parent_id == 0) {
      //   // Notify({ type: 'danger', message: '请输入上级地址', duration: 0 });
      //   this.bindShow = true
      // }
      // this.bind()
    },
    // bind() {
    //   if (this.userinfo.parent_id == 0 && this.inviteCode) {
    //     // this.show = true;
    //     this.register();
    //   } else if (this.userinfo.parent_id == 0 && !this.inviteCode) {
    //     this.show = true;
    //   }
    // },
    register() {
      // let web3 = new Web3(window.web3.currentProvider);
      // web3.eth.personal.sign(web3.utils.fromUtf8('register'), this.account, (err, res) => {
      if (!this.inviteCode) {
        Notify({ type: 'danger', message: '请输入上级钱包地址' });
        this.show = true;
        return;
      }
      let data = {
        address: this.account,
        inviteCode: this.inviteCode,
        // sign: res,
      };
      //注册接口 看信息
      homeApi.auth(data).then(res => {
        console.log("走了这里11");
        //我是注册接口
        if (res.code == 200) {
          if (res.data.parent_id != 0) {
            Notify({ type: 'primary', message: res.msg });
            this.bindShow = false;
            this.show = false;
            this.init();            
            localStorage.setItem('userinfo', JSON.stringify(res.data));
            localStorage.setItem('token__' + account, res.data.token);
            localStorage.setItem('parentId', res.data.parent_id);
            
          } else {
            this.show = true;
            Notify({ type: 'danger', message: res.msg });
          }
        } else {
          this.show = true;
          Notify({ type: 'danger', message: res.msg });
        }
      });
      // });
    },
    updateInfo() {
      //这个地方是干嘛的？获取用户信息吗对yoki说用这个接口 那得让他加个接
      homeApi.getUserInfo({ address: this.account, inviteCode: this.inviteCode }).then(res => {
        console.log(res);
        if (res.code == 200) {
          localStorage.setItem('userinfo', JSON.stringify(res.data));
          // localStorage.setItem('token', res.data.token);
          localStorage.setItem('parentId', res.data.parent_id);
          this.userinfo = res.data;
          // if (this.userinfo.parent_id == 0 && this.inviteCode) {
          //   // this.show = true;
          //   this.register();
          // } else if (this.userinfo.parent_id == 0 && !this.inviteCode) {
          //   this.show = true;
          // }
          if (this.userinfo.parent_id == 0) {
            
            this.register()
           
          }
        } else {
        }
      });
    },
    toNotice() {
      this.$router.push({ name: 'bulletin' });
    },
    jump() {
      this.$router.push({ name: 'lease' });
    },
    toIntroduce() {
      this.$router.push({ name: 'introduce' });
    },
    // getAnnouncement() {
    //   homeApi.getAnnouncement().then(res => {
    //     if (res.code == 200) {
    //       this.noticeText = res.data.rows[0].body;
    //     }
    //   });
    // },
    getIndexInfo() {
      homeApi.getIndexAllInfo().then(res => {
        if (res.code == 200) {
          this.indexinfo = res.data;
        }
      });
    },
    toLink(e) {
      window.location.href = e;
    },
    toAssets() {
      this.$router.push({ name: 'assets' });
    },
    toPage(type) {
      this.$router.push({ name: 'datainfo', query: { type } });
    },
    toTeam() {
      this.$router.push({ name: 'miner' });
    },
  },
  computed: {
    ...mapState(['account']),
  },
  watch: {
    account(e) {
      // this.init();
      // this.bind();
    },
  },
  components: {
    Header,
  },
};
</script>

<style lang="less" scoped>
.home_page {
  // padding: 0 20px;
  padding-bottom: 30px;
  position: relative;
  .banner {
    height: 180px;
    color: #fff;
    font-size: 22px;
    // font-weight: bold;
    // background: url('~@/assets/image/banner.png') no-repeat top/100% 100%;
    // padding: 40px 0 0 20px;
    .my-swipe {
      width: 100vw;
      height: 100%;
      .swipe-item {
        width: 100vw;
        height: 100%;
        img {
          width: 100vw;
          height: 100%;
        }
      }
      .swipe-item:first-child {
        position: relative;
        .item_text {
          position: absolute;
          z-index: 2;
          left: 20px;
          top: 40px;
        }
      }
    }
  }
  .content {
    padding: 0 20px;
    // margin-top: -16px;
    top: 202px;
    position: absolute;
    .aleo {
      width: 100%;
      margin-bottom: 6px;
      border-radius: 14px;
    }
    .caption {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #238be8;
      font-size: 16px;
      font-weight: bold;
      border-left: 4px solid #238be8;
      padding-left: 6px;
      margin-bottom: 16px;
      .right_arrow {
        display: block;
        width: 10px;
        height: 12px;
      }
    }

    .slogan {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #fff;
      box-shadow: 0 0 4px rgba(77, 146, 223, 0.16);
      border-radius: 14px;
      padding: 10px 14px;
      .welcome {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        .welcome_top {
          font-size: 16px;
          font-weight: bold;
        }
      }
      .lease {
        font-size: 14px;
        color: #fff;
        background: #238be8;
        border-radius: 20px;
        padding: 6px 12px;
      }
    }
    .total_box {
      background: #fff;
      box-shadow: 0 0 4px rgba(77, 146, 223, 0.16);
      border-radius: 14px;
      padding: 10px 14px;
      margin: 14px 0;
      .total_universal {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        color: #999;
        .universal_amount {
          font-size: 16px;
          font-weight: bold;
          color: #000;
          margin-top: 6px;
        }
      }
      .total_list {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    .introduce {
      font-size: 12px;
      line-height: 22px;
      color: #666;
      background: #fff;
      box-shadow: 0 0 4px rgba(77, 146, 223, 0.16);
      border-radius: 14px;
      padding: 20px;
      margin-bottom: 20px;
      .introduce_top {
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        .introduce_img {
          display: block;
          width: 76px;
          height: 72px;
          margin-right: 10px;
        }
        .intro {
          font-size: 14px;
          font-weight: bold;
          color: #238be8;
          margin-bottom: 10px;
        }
      }
    }
    .currency_list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .currency_li {
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        border-radius: 6px;
        height: 40px;
        padding: 0 10px;
        margin-bottom: 15px;
        .li_img {
          width: 100%;
        }
      }
    }
    .relation {
      display: flex;
      align-items: center;
      background: #fff;
      box-shadow: 0 0 4px rgba(77, 146, 223, 0.16);
      border-radius: 14px;
      padding: 20px 16px;
      .relation_li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;
        .logo_img {
          display: block;
          width: 45px;
          height: 45px;
          margin-bottom: 10px;
        }
        .img {
          height: 10px;
        }
      }
    }
  }
  .bind_box {
    padding: 20px 20px 30px;
    .address_field {
      border-bottom: 1px solid #cccccc9d;
      padding: 10px 0;
      margin: 20px 10px 20px;
    }
    .bind_button {
      color: #fff;
      text-align: center;
      background: #238be8;
      border-radius: 20px;
      padding: 10px 0;
    }
  }
}
</style>