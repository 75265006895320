<template>
  <div>
    <div class="head">
      <img @click="back" src="@/assets/image/arrow.png" class="arrow_img" />
      <span>公告详情</span>
    </div>
    <div class="details">
      <div class="title">
        <span>{{ dataObj.title }}</span>
      </div>
      <div class="time">
        <span>{{ dataObj.create_time }}</span>
      </div>
      <div class="text">
        <span>{{ dataObj.body }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataObj: {
        title: undefined,
        create_time: undefined,
        body: undefined,
      },
    };
  },
  created() {
    this.dataObj = this.$route.query;
  },
  methods: {
    //返回上一页
    back() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.head {
  position: relative;
  display: flex;
  justify-content: center;
  color: #000;
  background: #fff;
  padding: 20px 0 10px;
  .arrow_img {
    position: absolute;
    left: 20px;
    top: 46%;
    width: 10px;
    height: 16px;
    // transform: translate(0, -50%);
  }
}
.details {
  padding: 20px;

  .title {
    font-size: 16px;
    color: #333;
    font-weight: bold;
  }
  .time {
    font-size: 14px;
    color: #666;
    margin-top: 10px;
  }
  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #333;
    margin: 20px 0;
  }
}
</style>