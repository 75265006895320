import usdtAbi from '../config/abi/erc20.json'
import Web3 from 'web3'
import BigNumber from 'bignumber.js'
import store from '../store';
//转账  转账方法这 用这个方法进行转账  懂了吗  myAddress 当前连接地址  to 合约地址  amount 数量 currencyAddredd 转账币种地址-》 paytoken
function transfer(myAddress, to, amount, currencyAddress) {
    console.log(myAddress, to, amount, currencyAddress);
    return new Promise(async function (resolve, reject) {
        let web3 = new Web3(window.web3.currentProvider);
        console.log(myAddress, to, amount, currencyAddress);
        let crossingOkContractInstance = new web3.eth.Contract(usdtAbi, currencyAddress);
        const gas = await crossingOkContractInstance.methods.transfer(to, new BigNumber(amount).times(new BigNumber(10).pow(18)).toString()).estimateGas({ from: myAddress });
        let hash = ''
        crossingOkContractInstance.methods.transfer(to, new BigNumber(amount).times(new BigNumber(10).pow(18)).toString()).send({ from: myAddress, gas: gas , gasPrice :5000000000})
            .on('transactionHash', res => {
                //拿到hash 交易上链
                hash = res
            }).on('confirmation', res => {
                //连上确认返回hash值
                resolve(hash)
            }).on('error', res => {
                reject(res)
            })
    })
}
//授权
function approve(myAddress, to, amount, currencyAddress) {
    return new Promise(async function (resolve, reject) {
        let web3 = new Web3(window.web3.currentProvider);
        let crossingOkContractInstance = new web3.eth.Contract(usdtAbi, currencyAddress);
        const gas = await crossingOkContractInstance.methods.approve(to, new BigNumber(amount).times(new BigNumber(10).pow(18)).toString()).estimateGas({ from: myAddress });
        crossingOkContractInstance.methods.approve(to, new BigNumber(amount).times(new BigNumber(10).pow(18)).toString()).send({ from: myAddress, gas:  gas , gasPrice :5000000000 })
            .on('transactionHash', res => {
                
            }).on('confirmation', res => {
                resolve(res)
            }).on('error', res => {

                reject(res)
            })
    })
}


export default {
    transfer,
    approve
    
}