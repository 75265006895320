<template>
  <div>
    <div class="head">
      <img @click="back" src="@/assets/image/arrow.png" class="arrow_img" />
      <span>资产明细</span>
    </div>
    <div class="assets">
      <div class="total_box">
        <span>算力总额</span>
        <span class="total">{{ Math.floor(power * 100) / 100 || 0 }}</span>
      </div>
      <div class="caption">
        <span>算力明细</span>
        <van-popover v-model="showPopover" placement="bottom-end" trigger="click" :actions="actions" @select="onSelect">
          <template #reference>
            <span class="right_arrow">{{ typeText || '全部' }}</span>
          </template>
        </van-popover>
      </div>

      <div class="bare" v-if="total < 1">
        <span>暂无数据</span>
      </div>
      <div v-else>
        <!-- <div v-for="(item, index) of 5" :key="index" class="li">
          <div class="li_left">
            <span>直推奖励</span>
            <span class="time">2023-05-09 10:25</span>
          </div>
          <span class="li_right">+245.23</span>
        </div> -->
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <div v-for="(item, index) in list" :key="index" class="li">
            <div class="li_left">
              <!-- <span>{{ item.action == 1 ? '租赁算力' : item.action == 2 ? '直推奖励' : item.action == 3 ? '算力奖励' : '平级奖励' }}</span> -->
              <span>{{ typeFormatFn(item.action) }}</span>
              <span class="time">{{ dateFormatFn(item.create_time) }}</span>
            </div>
            <span class="li_right">{{ Math.floor(item.change_amount * 100) / 100 || 0 }}</span>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import { homeApi } from '@/api/index';
import {dateFormat,typemat} from "@/utils/formatTool.js"
export default {
  data() {
    return {
      page: 0,
      loading: false,
      finished: false,
      total: 1,
      list: [],
      power: 0,
      actions: [],
      showPopover: false,
      typeText: '全部',
      actionType: -1, //-1:全部 0:未知 1:租赁 2:直推奖励 3:团队的算力奖励 4:团队的平级算力奖励
    };
  },
  mounted() {
    this.getAccountInfo();
    this.actionGet();
    this.onLoad();
  },
  methods: {
    //返回上一页
    back() {
      this.$router.back();
    },
    dateFormatFn(date){
      return dateFormat(new Date(date *1000), "yyyy-MM-dd hh:mm:ss")
    },
    typeFormatFn(type) {
      return typemat(type);
    },
    getAccountInfo() {
      homeApi.getUserInfo({}).then(res => {
        if (res.code == 200) {
          this.power = Math.floor(res.data.power * 100) / 100;
        }
      });
    },
    actionGet() {
      let params = {
        type: 1,
      };
      homeApi.actionGet(params).then(res => {
        if (res.code == 200) {
          this.actions = res.data;
        }
      });
    },
    powerList() {
      let data = {
        page: this.page,
        query: {
          action: this.actionType,
        },
      };
      this.loading = true;
      this.finished = false;
      homeApi.powerList(data).then(res => {
        if (res.code == 200) {
          let rows = res.data.rows;
          // 如果返回的数组是空或数组长度是0
          if (rows == null || rows.length === 0) {
            this.loading = false; // 关闭加载状态
            this.finished = true; // 加载结束
            this.total = 0
            return;
          }
          this.loading = false; // 关闭加载状态
          this.total = res.data.total; //数据总条数
          this.list = this.list.concat(rows); //合并数组
          console.log(this.list);

          // 如果合并之后的数组长度大于返回的数据总条数
          if (this.list.length >= this.total) {
            this.finished = true; // 加载结束
          }
        } else {
          this.total = 0;
          this.loading = false; // 关闭加载状态
          this.finished = true; // 加载结束
        }
      });
    },
    onSelect(action) {
      this.actionType = action.value;
      this.typeText = action.text;
      this.page = 0;
      this.list = [];
      this.onLoad();
    },
    onLoad() {
      this.page += 1;
      this.powerList();
    },
  },
};
</script>

<style lang="less" scoped>
.head {
  position: relative;
  display: flex;
  justify-content: center;
  color: #000;
  background: #fff;
  padding: 20px 0 10px;
  .arrow_img {
    position: absolute;
    left: 20px;
    top: 46%;
    width: 10px;
    height: 16px;
    // transform: translate(0, -50%);
  }
}
.assets {
  padding: 20px;
  .total_box {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #fff;
    background: #238be8;
    border-radius: 12px;
    padding: 20px;
    .total {
      font-size: 26px;
      font-weight: bold;
      margin-top: 14px;
    }
  }
  .caption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #238be8;
    font-size: 14px;
    font-weight: bold;
    border-left: 4px solid #238be8;
    padding-left: 6px;
    margin: 20px 0 10px;
    .right_arrow {
      font-size: 12px;
      font-weight: normal;
    }
  }
  .li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eeeeee;
    padding: 10px 0;
    .li_left {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      color: rgba(51, 51, 51, 1);
      .time {
        color: rgba(102, 102, 102, 1);
        margin-top: 4px;
      }
    }
    .li_right {
      font-size: 16px;
      color: #000;
    }
  }
  .bare {
    text-align: center;
    font-size: 14px;
    padding: 60px 14px;
    color: #888;
  }
}
</style>