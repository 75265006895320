<template>
  <div class="withdraw">
    <div class="head">
      <img @click="back" src="@/assets/image/arrow.png" class="arrow_img" />
      <span>租赁记录</span>
    </div>

    <div class="list_title">
      <span style="text-align: center">资金类型</span>
      <span style="text-align: center">数量</span>
      <span style="text-align: center">状态</span>
      <span style="text-align: right">日期</span>
    </div>

    <!-- <div v-else class="list_box">
        <div v-for="(item, index) of list" :key="index" class="li">
          <span>算力</span>
          <span style="text-align: center">+256.2623</span>
          <span style="text-align: right">2023-05-07</span>
        </div>
      </div> -->
    <div v-if="total < 1" class="bare">
      <span>暂无数据</span>
    </div>
    <div v-else>
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" class="list_box">
        <!-- <van-cell v-for="(item,index) in list" :key="index" :title="item.title" /> -->
        <div v-for="(item, index) in list" :key="index" class="li">
          <!-- <span>{{ getHash(item.tx_hash) }}</span> -->
          <span style="text-align: center" v-if="item.type==1">钱包余额</span>
          <span style="text-align: center" v-else>系统余额</span>
          <span style="text-align: center">{{ Math.floor(item.amount * 100) / 100 || 0 }}</span>
          <span style="text-align: center">{{ item.status ? '成功' : '待确认'}}</span>
          <span style="text-align: right">{{ dateFormatFn(item.create_time) }}</span>
        </div>
      </van-list>
    </div>
  </div>
</template>
  
  <script>
import { homeApi } from '@/api/index';
import { dateFormat } from '@/utils/formatTool.js';
export default {
  data() {
    return {
      list: [],
      page: 0,
      loading: false,
      finished: false,
      total: 1,
      realList: 0,
    };
  },
  mounted() {
    // this.onLoad();
  },
  methods: {
    dateFormatFn(date) {
      return dateFormat(new Date(date * 1000), 'yyyy-MM-dd hh:mm:ss');
    },
    //返回上一页
    back() {
      this.$router.back();
    },
    getHash(hash) {
      if (hash) {
        return '0x...' + hash.substring(hash.length - 12, hash.length);
      } else {
        return '';
      }
    },
    rechargeList() {
      let params = {
        page: this.page,
        query: {},
      };
      homeApi.rechargeList(params).then(res => {
        console.log(res);
        if (res.code == 200) {
          let rows = res.data.rows;
          // 如果返回的数组是空或数组长度是0
          if (rows == null || rows.length === 0) {
            this.loading = false; // 关闭加载状态
            this.finished = true; // 加载结束
            this.total = 0;
            return;
          }
          this.loading = false; // 关闭加载状态
          this.total = res.data.total; //数据总条数
          this.list = this.list.concat(rows); //合并数组
          console.log(this.list);

          // 如果合并之后的数组长度大于返回的数据总条数
          if (this.list.length >= this.total) {
            this.finished = true; // 加载结束
          }
        } else {
          this.total = 0;
          this.loading = false; // 关闭加载状态
          this.finished = true; // 加载结束
        }
      });
    },
    onLoad() {
      this.page += 1;
      this.rechargeList();
    },
  },
};
</script>
  
<style lang="less" scoped>
.withdraw {
  height: 100%;
  background: #fff;

  .head {
    position: relative;
    display: flex;
    justify-content: center;
    color: #000;
    background: #fff;
    padding: 20px 0 10px;
    .arrow_img {
      position: absolute;
      left: 20px;
      top: 46%;
      width: 10px;
      height: 16px;
      // transform: translate(0, -50%);
    }
  }
  .list_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
    padding: 20px 30px 14px;
    margin-top: 6px;
    // margin-bottom: 20px;
    span {
      width: 30%;
    }
  }
  .list_box {
    padding-bottom: 10px;
    .li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      color: #666666;
      padding: 10px 30px 0;
      span {
        width: 30%;
      }
    }
  }
  .bare {
    text-align: center;
    font-size: 14px;
    padding: 14px;
    color: #888;
  }
}
</style>
