<template>
  <div>
    <div class="BottomNavigation">
      <div class="Navigation_left">
        <!-- <img class="logo"
             src="@/assets/img/logo.png"
        alt />-->
        <!-- <span>$ {{price}}</span> -->
      </div>
      <!-- <div class="menu_list">
        <div class="settings" v-for="(item,index) of menuList" :key="index">
          <div class="language_switching" @click="Navigation(index,item.router)">
            <img class="language" :src="item.image" />
            <span>{{ item.name }}</span>
          </div>
          <div v-show="item.subordinate.length != 0">
            <div class="multilingual">
              <div
                v-for="(e, i) of item.subordinate"
                :key="i"
                @click="BottomNavigation(index,e.router)"
              >
                <span>{{ e.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="Navigation_right">
        <img class="plane"
             src="@/assets/img/plane.png"
             alt />
        <img class="bird"
             src="@/assets/img/bird.png"
             alt />
      </div>
    </div>

    <div class="mobile_phone">
      <div class="navigation_list"
           v-for="(item,index) of navigationList"
           :key="index"
           @click="languageSwitching(item.name)">
        <img :src=" routeName == item.name?item.bright:item.img"
             class="li_img" />
        <span :style="{'color': routeName == item.name?'rgb(0, 120, 255)':'rgb(75, 86, 107)'}">{{ item.text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { usApi,homeApi } from '@/api/index';
import { mapState } from 'vuex';
export default {
  data () {
    return {
      showPopover: false,
      language: '',
      price: 0,
      menuList: [],
      navigationList: [
        { img: require('@/assets/image/icon/home.png'), bright: require('@/assets/image/icon/home-active.png'), text: '首页', name: 'Index' },
        { img: require('@/assets/image/icon/box.png'), bright: require('@/assets/image/icon/box-active.png'), text: '团队', name: 'miner' },
        { img: require('@/assets/image/icon/market.png'), bright: require('@/assets/image/icon/market-active.png'), text: '我的', name: 'orders' },
      ],
      routeName: '0',
      invite_link: '',
    };
  },
  async created () {
    this.language = await localStorage.getItem('lang');
    // this.getUserInfo();
    // this.routeName = this.$route.name
  },
  computed: {
    ...mapState(['account']),
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.name == 'Swap' || val.name == 'LiquidityIndex' || val.name == 'Liquidity' || val.name == 'importMining' || val.name == 'Remove') {
          this.routeName = 'Swap';
        } else if (val.name == 'consult' || val.name == 'consultDetail') {
          this.routeName = 'consult';
        } else {
          this.routeName = val.name;
        }
      },
      // 深度观察监听
      deep: true,
      immediate: true,
    },
    account () {
      // this.getUserInfo();
    },
  },
  methods: {
    Navigation (i, router) {

      if (router != '') {
        this.$router.push({ name: router });
      } else {
        this.close(i);
      }
    },
    BottomNavigation (i, router) {

      if (router != '') {
        this.$router.push({ name: router });
        this.close(i);
      } else {
        this.$toast(this.$t('lang.swap109'));
      }
    },
    close (i) {
      this.menuList[i].showPopover = !this.menuList[i].showPopover;
    },
    languageSwitching (router) {
      if (this.routeName == router) return;
      if (router != '') {
        this.$router.push({ name: router });
      } else {
        this.$toast(this.$t('lang.swap109'));
      }
    },
    getPath () { },
    //我的信息
    getUserInfo () {
      if (this.account) {
        this.invite_link = ''
        homeApi.getUserInfo(this.account).then(res => {
          if (res.code == 200) {
            // this.userinfo = res.data;
            // this.parent_id = res.data.parent_id;
            if (res.data.parent_id != 0) {
              this.invite_link = res.data.invite_link;
            }
          } else {
            this.$toast(res.msg);
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.BottomNavigation {
  position: fixed;
  bottom: 0;
  left: 0;
  display: none;
  justify-content: space-between;
  width: 100%;
  // height: 50px;
  // border: 3px solid #f39714;
  background: #1e1e1e;
  border-radius: 16px 16px 0 0;
  padding: 0 20px;
  .Navigation_left {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    margin-right: 20px;
    .logo {
      width: 34px;
      height: 32px;
      margin-right: 10px;
    }
  }
  .menu_list {
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin: 0 90px;
    // padding: 12px 0;
    .settings {
      display: flex;
      position: relative;
      font-weight: bold;
      font-family: YouSheBiaoTiYuan-Regular, YouSheBiaoTiYuan;

      .language_switching {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: bold;

        color: #333333;
        border-radius: 16px;
        padding: 12px 12px 12px 8px;
        cursor: pointer;
        .language {
          width: 22px;
          height: 22px;
        }
      }

      .multilingual {
        display: none;
        position: absolute;
        top: 0px;
        right: 50%;
        font-size: 14px;
        width: 92px;
        color: #333;
        background: #04152d;
        // border: 1px solid #F39714;
        box-shadow: 0px 2px 6px 0px rgba(16, 27, 82, 0.5);
        border-radius: 6px;
        text-align: center;
        padding: 2px;
        transform: translate(50%, -100%);
        z-index: 999;
        cursor: pointer;

        div {
          padding: 6px 0;
        }
        .special {
          color: #f39714;
        }
        div {
          &:hover {
            color: #f39714;
          }
        }
      }
      .overlay {
        position: fixed;
        z-index: 998;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
      &:hover {
        .language_switching {
          transform: scale(1.3, 1.3);
        }
        .multilingual {
          display: block;
        }
      }
    }
  }
  .Navigation_right {
    display: flex;
    align-items: center;

    .plane {
      width: 36px;
      height: 36px;
      cursor: pointer;
    }
    .bird {
      width: 36px;
      height: 36px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
.mobile_phone {
  display: none;
  z-index: 999;
}
// @media screen and (max-width: 900px) {
//   .BottomNavigation {
//     display: flex;
//     padding: 0 10px;
//     .Navigation_left {
//       font-size: 18px;
//     }
//     .menu_list {
//       flex: 1;

//       margin: 0;
//       .settings {
//         // flex: 1;
//         // justify-content: space-between;
//         margin: 0;
//         .language_switching {
//           font-size: 12px;
//         }
//       }
//     }
//   }
// }
// @media screen and (max-width: 700px) {
.BottomNavigation {
  display: none;
}
.mobile_phone {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  // height: 50px;
  background: #ffffff;
  padding: 4px 0;
  .navigation_list {
    flex: 1 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      font-size: 10px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: rgb(75, 86, 107);
      transform: scale(0.9, 0.9);
    }
    .li_img {
      width: 26px;
      height: 26px;
    }
  }
}
// }
</style>