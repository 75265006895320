<template>
  <div class="sidebarShow"
       :class="pageShow ? 'open' : ''">
    <div class="user"
         v-show="pageShow">
      <div class="invitation">
        <div>
          <img src="@/assets/img/invitations.png"
               class="invitation_img" />
          <span>邀请</span>
        </div>
        <div class="more"
             @click="toNav('Invitation')">
          <span>{{$t('lang.swap156')}}</span>
        </div>
      </div>
      <div class="address">
        <div class="invitation_address">
          <span>{{ invited || '请先添加邀请人' }}</span>
        </div>
        <div class="copys"
             @click="copy"
             :data-clipboard-text="this.invite_link">
          <span>复制</span>
        </div>
      </div>
    </div>
    <div class="mobile_phone">
      <div class="navigation_list"
           v-show="!pageShow"
           @click="toNav('Invitation')">
        <div class="li">
          <img src="@/assets/img/invitationa.png"
               class="li_img" />
          <span>邀请</span>
        </div>
        <span>></span>
      </div>
      <div class="navigation_list"
           v-for="(item, index) of navigationList"
           :key="index"
           @click="toNav(item.name)">
        <div class="li">
          <img :src="item.bright"
               class="li_img" />
          <span>{{ $t(item.text) }}</span>
        </div>
        <span>></span>
      </div>
    </div>

    <div class="submenu">
      <div class="submenu_list"
           v-for="(item, index) of submenuList"
           :key="index"
           @click="toNav(item.name)">
        <div class="li">
          <img :src="item.img"
               class="submenu_img" />
          <span>{{ $t(item.text) }}</span>
        </div>
        <span>></span>
      </div>
    </div>
  </div>
</template>

<script>
import Clipboard from 'clipboard';
import { mapState } from 'vuex';
export default {
  props: ['pageShow'],
  data () {
    return {
      navigationList: [
        // { img: require('@/assets/img/home_pagesash.png'), bright: require('@/assets/img/home_pagesashbright.png'), text: 'lang.swap85', name: 'Index' },
        // // { img: require('@/assets/img/transactions.png'), bright: require('@/assets/img/transactionsbright.png'), text: 'lang.swap88', name: 'Swap' },
        // // { img: require('@/assets/img/wallets.png'), bright: require('@/assets/img/walletsbright.png'), text: 'lang.swap197', name: 'Mining' },
        // { img: require('@/assets/img/communitys.png'), bright: require('@/assets/img/communitysbright.png'), text: 'lang.swap198', name: '' },
      ],
      submenuList: [
        // {
        //   img: require('@/assets/img/rightsAndInterests.png'),
        //   text: 'lang.swap60',
        //   name: '',
        // },
        // {
        //   img: require('@/assets/img/rechange.png'),
        //   text: 'lang.swap1',
        //   name: 'FlashExchange',
        // },
        {
          img: require('@/assets/img/rightsAndInterests.png'),
          text: '',
          name: 'StakePool',
        },
        {
          img: require('@/assets/img/crowdFunding.png'),
          text: '',
          name: 'StartingCenter',
        },
        {
          img: require('@/assets/img/government.png'),
          text: '',
          name: 'govern',
        },
        {
          img: require('@/assets/img/notices.png'),
          text: '',
          name: 'NoticeIndex',
        },
        {
          img: require('@/assets/img/feedback.png'),
          text: '',
          name: 'Feedback',
        },

        {
          img: require('@/assets/img/understand.png'),
          text: '',
          name: 'Understand',
        },
        {
          img: require('@/assets/img/gameFi.png'),
          text: '',
          name: 'GameFi',
        },
      ],
      invite_link: '',
    };
  },
  methods: {
    handleScroll () {
      // this.pageShow = !this.pageShow
      this.$emit('handleScroll');
    },
    // 获取邀请地址
    init () {
      if (this.account) {

      }
    },
    // 复制
    copy () {
      if (this.invite_link) {
        var clipboard = new Clipboard('.copys');
        clipboard.on('success', e => {
          this.$toast(this.$t('lang.swap222'));
          clipboard.destroy(); // 释放内存
        });
        clipboard.on('error', e => {
          this.$toast(this.$t('lang.swap223'));
          clipboard.destroy(); // 释放内存
        });
      }
    },
    toNav (router, title) {
      if (this.$route.name == router) return
      if (router != '') {
        this.$router.push({ name: router });
      } else {
        this.$toast(this.$t('lang.swap109'));
      }
    },
  },
  watch: {
    account () {
      this.init();
    },
  },
  created () {
    this.init();
  },
  computed: {
    ...mapState(['account']),
    invited () {
      if (this.account && this.invite_link) {
        return this.account.substring(0, 7) + '*****' + this.account.substring(this.account.length - 6, this.account.length);
      } else {
        return '';
      }
    },
  },
};
</script>

<style lang="less" scoped>
span {
  white-space: nowrap;
}
.user {
  font-size: 18px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  background: linear-gradient(180deg, #f39714 0%, #fff1c4 100%);
  border-radius: 16px;
  padding: 10px 14px;
  margin: 20px 0;
  .invitation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .invitation_img {
      width: 26px;
      height: 26px;
    }
    .more {
      font-size: 12px;
      border-radius: 16px;
      border: 1px solid #ffffff;
      padding: 2px 12px;
    }
  }
  .address {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: #333333;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    margin-top: 20px;
    .invitation_address {
      background: #fff;
      border-radius: 16px;
      padding: 4px 16px;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; //溢出不换行
    }
    .copys {
      background: #fff;
      border-radius: 16px;
      white-space: nowrap;
      padding: 4px 16px;
      margin-left: 10px;
    }
  }
}
.sidebarShow {
  position: fixed;
  left: 0;
  top: 50px;
  height: calc(100% - 50px);
  width: 60px;
  background: #ffffff;
  box-shadow: 2px 3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 0px 10px 10px 0px;
  transition: width 0.5s;
  padding: 0 20px;
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE10+ */
  &::-webkit-scrollbar {
    display: none; /* ChromeSafari */
  }
  .put_away {
  }
  .mobile_phone {
    display: flex;
    flex-direction: column;
    width: 100%;
    // height: 50px;
    background: #fff;
    padding: 4px 0;
    .navigation_list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 18px 0;
      // border-bottom: 1px solid #999999;
      cursor: pointer;

      .li {
        display: flex;
        align-items: center;
        span {
          font-size: 14px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
          color: #1b2333;
          transform: scale(0.9, 0.9);
        }
        .li_img {
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }
      }
    }
    .last {
      border-bottom: 0;
    }
  }
  .submenu {
    width: 100%;
    // margin-top: 50px;
    .submenu_list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      // padding: 10px 8px;
      margin: 20px 8px;
      cursor: pointer;
      .li {
        display: flex;
        align-items: center;
        .submenu_img {
          width: 22px;
          height: 22px;
          margin-right: 10px;
        }
      }
    }
  }
}
.open {
  width: 240px !important;
  .navigation_list {
  }
}
@media screen and (max-width: 700px) {
  .sidebarShow {
    display: none;
  }
}
</style>