<template>
  <div class="service">
    <div class="head">
      <img @click="back" src="@/assets/image/arrow.png" class="arrow_img" />
      <span>服务器产品</span>
    </div>
    <img src="@/assets/image/my/service_bg.png" alt="" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    back() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.service {
  .head {
    position: relative;
    display: flex;
    justify-content: center;
    color: #000;
    background: #fff;
    padding: 20px 0 10px;
    .arrow_img {
      position: absolute;
      left: 20px;
      top: 46%;
      width: 10px;
      height: 16px;
      // transform: translate(0, -50%);
    }
    .right_text {
      position: absolute;
      right: 20px;
    }
  }
  width: 100%;
  height: calc(100% - 56px);
  img {
    width: 100%;
    height: 100%;
  }
  /* background: url("@/s") no-repeat; */
}
</style>