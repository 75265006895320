export const dateFormat = (date, fmt) => {
    //格式化时间
    var o = {
        "M+": date.getMonth() + 1, // 月份
        "d+": date.getDate(), // 日
        "h+": date.getHours(), // 小时
        "m+": date.getMinutes(), // 分
        "s+": date.getSeconds(), // 秒
        "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
        S: date.getMilliseconds() // 毫秒
    };
    fmt = fmt || "yyyy-MM-dd";
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
            RegExp.$1,
            (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
    }
    for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(
                RegExp.$1,
                RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
            );
        }
    }
    return fmt;
};

export const typemat = (type) => {
    let result
    switch (type) {
        case -1:
            result = "全部"
            break;
        case 1:
            result = "租赁算力"
            break;
        case 2:
            result = "直推奖励"
            break;
        case 3:
            result = "算力奖励"
            break;
        case 4:
            result = "平级奖励"
            break;
        case 10:
            result = "复购算力"
            break;
        case 11:
            result = "代数收益"
            break;
        case 12:
            result = "加权分红"
            break;
        case 15:
            result = "提现"
            break;
        case 16:
            result = "提现退回"
            break;
        case 20:
            result = "算力收益"
            break;
        case 21:
            result = "管道收益"
            break;
        case 99:
            result = "系统调整"
            break;
        case 999:
            result = "其它"
            break;
        default:
            result = "未知"
    }
    return result
    // if (type == -1) {
    //     return "全部"
    // } else if (type == 1) {
    //     return "租赁算力"
    // } else if (type == 2) {
    //     return "直推奖励"
    // } else if (type == 3) {
    //     return "算力奖励"
    // } else if (type == 4) {
    //     return "平级奖励"
    // } else if (type == 10) {
    //     return "复购算力"
    // } else if (type == 11) {
    //     return "代数收益"
    // } else if (type == 12) {
    //     return "加权分红"
    // } else if (type == 15) {
    //     return "提现"
    // } else if (type == 16) {
    //     return "提现退回"
    // } else if (type == 20) {
    //     return "算力收益"
    // } else if (type == 21) {
    //     return "管道收益"
    // } else if (type == 99) {
    //     return "系统调整"
    // } else if (type == 999) {
    //     return "其它"
    // } else {
    //     return "未知"
    // }
};