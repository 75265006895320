<template>
  <div>
    <div v-if="condition ==1"
         class="synthesis ">
      <div class="title">
        <span>购买成功</span>
      </div>
    </div>
    <div v-else-if="condition ==2"
         class="synthesis fail">
      <div class="title">
        <span>购买失败</span>
      </div>
    </div>
    <div v-else
         class="product">
      <div class="caption">
        <span>某某产品</span>
      </div>
      <div class="describe">
        <span>产品描述产品描述产品描述</span>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  props: ['title', 'condition'],
}
</script>

<style lang="less" scoped>
.synthesis {
  width: 300px;
  background: url('~@/assets/img/synthesis_succeed.png') no-repeat;
  background-size: 100% 100%;
  // height: 200px;
  .title {
    display: flex;
    justify-content: center;
    color: #222;
    font-size: 24px;
    font-weight: bold;
    padding: 40px 0 240px;
    // transform: translateX(0) translateY(40%);
    // background: linear-gradient(90deg, rgba(244, 255, 250, 1) 0%, rgba(230, 238, 255, 0.78) 49%, rgba(255, 227, 234, 1) 100%);
  }
}
.fail {
  background: url('~@/assets/img/synthesis_fail.png') no-repeat;
  background-size: 100% 100%;
}
.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  background: url('~@/assets/img/synthesis_product.png') no-repeat;
  background-size: 100% 100%;
  .caption {
    font-size: 24px;
    font-weight: bold;
    margin: 20px 0 260px;
  }
  .describe {
    margin-bottom: 40px;
  }
}
</style>