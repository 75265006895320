<template>
  <div class="orders">
    <div class="banner">
      <div class="avatar">
        <img src="@/assets/image/logo.png" class="fd_img" />
        <span class="copys" @click="copyAccount" :data-clipboard-text="this.account">{{ getAccount || '请先链接钱包' }}</span>
      </div>
      <div class="grade">
        <img src="@/assets/image/grade.png" class="grade_img" />
        <span>{{ userinfo.team_level_name || '青铜' }}</span>
      </div>
    </div>
    <div class="content">
      <div class="balance_box">
        <div class="balance_li">
          <div class="left">
            <span>USDT余额</span>
            <span class="amount">{{ Math.floor(userinfo.usdt * 100) / 100 || 0 }}</span>
          </div>
          <div class="right" @click="withdrawShow = true">
            <div class="btn">提现</div>
          </div>
        </div>
        <div class="balance_li">
          <div class="left">
            <span>平台币余额</span>
            <span class="amount">{{ Math.floor(userinfo.token * 100) / 100 || 0 }}</span>
          </div>
        </div>
      </div>
      <div class="total_box" v-if="userinfo.servers_power > 0">
        <span>服务器算力总额</span>
        <span class="total">{{ userinfo.servers_power || 0 }}</span>
      </div>
      <div class="list">
        <div v-for="(item, index) of list" :key="index" @click="switchovers(item)" :style="{ 'border-top': index != 0 ? ' 1px solid #dfeffd' : '' }" class="li">
          <div class="li_left">
            <img :src="item.image" class="li_img" />
            <span>{{ item.name }}</span>
            <img src="@/assets/image/my/fire.png" v-if="index == 0" class="fire_img" />
          </div>
          <span>></span>
        </div>
      </div>
    </div>
    <!-- 上级弹窗 -->
    <van-popup v-model="show" round style="width: 80%">
      <Superior :address="userinfo.parent_address" :level="userinfo.team_level_name" :parantId="userinfo.parent_id"></Superior>
    </van-popup>

    <!-- 提现弹框 -->
    <van-popup v-model="withdrawShow" round style="width: 80%">
      <div class="withdrawPopup">
        <div class="title">
          <span>提现</span>
        </div>
        <div>
          <div>
            <span>提现金额</span>
          </div>
          <div>
            <van-field type="number" v-model="withdrawAmount" placeholder="0" />
          </div>
          <div class="tip">
            <span>{{ '最低提现金额：' + feeObj.withdraw_min_amount + ' USDT' }}</span>
            <span style="padding: 6px 0">{{ '提现手续费：' + Number(feeObj.withdraw_fee_rate) * 100 + '%' }}</span>
            <span>{{ '实际到账：' + receiptAmount + ' USDT' }}</span>
          </div>
          <div>
            <div class="btn" @click="withdrawSubmit">
              <span>提现</span>
            </div>
          </div>
          <div class="close—icon" @click="withdrawShow = false">
            <van-icon name="close" size="24" color="#C8C9CC" />
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Superior from './superiorPopup.vue';
import { Notify } from 'vant';
import { usApi, newsLetterApi, walletApi, homeApi } from '@/api/index';
import { mapState } from 'vuex';
import union from '../../api/union';
import Clipboard from 'clipboard';
import Web3 from 'web3';
export default {
  data() {
    return {
      show: false, //上级弹窗状态
      withdrawShow: false, //提现弹窗状态
      list: [
        {
          image: require('@/assets/image/my/service.png'),
          name: '服务器产品',
          router: 'service',
        },
        {
          image: require('@/assets/image/my/withdraw.png'),
          name: '提现记录',
          router: 'withdrawRecord',
        },
        {
          image: require('@/assets/image/my/assets.png'),
          name: '资产明细',
          router: 'assets',
        },
        {
          image: require('@/assets/image/my/invite.png'),
          name: '邀请链接',
          router: 'invite',
        },
        {
          image: require('@/assets/image/my/bulletin.png'),
          name: '公告列表',
          router: 'bulletin',
        },
        {
          image: require('@/assets/image/my/customer.png'),
          name: '联系客服',
          router: '',
        },
        {
          image: require('@/assets/image/my/superior.png'),
          name: '我的上级',
          router: 'superior',
        },
      ],
      domain: '', //图片域名
      basicBannerList: [{ image: require('@/assets/img/turntable.jpg') }],
      imageDomain: '',
      walletList: [], //资产列表
      total: 0,
      team_level_name: '',
      userinfo: {
        power: undefined,
        token: undefined,
        usdt: undefined,
      },
      // indexinfo: {
      //   power: undefined,
      //   token: undefined,
      //   usdt: undefined,
      // },
      withdrawAmount: 0, // 提现金额
      feeObj: {
        usdt: 0,
        withdraw_fee_rate: 0,
        withdraw_min_amount: 0,
      },
      receiptAmount: 0, // 实际到账金额
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.account) {
        // this.basicConfig();
        this.getUserInfo();
        // this.getAccountInfo();
        this.withdrawFee();
      }
    },
    // 资产列表
    getWalletList() {
      walletApi.getWalletList(this.account).then(res => {
        this.walletList = res.data;
        for (let i of this.walletList) {
          this.total += i.equal_usd;
        }
      });
    },
    //首页banner
    basicBanner() {
      newsLetterApi.basicBanner().then(res => {
        if (res.code == 200) {
          // this.basicBannerList = res.data;
          this.getWalletList();
        } else {
          this.$toast(res.msg);
        }
      });
    },
    basicConfig() {
      newsLetterApi.basicConfig().then(res => {
        if (res.code == 200) {
          this.domain = res.data.domain;
          this.imageDomain = res.data.image_domain;
          this.basicBanner();
        } else {
          this.$toast(res.msg);
        }
      });
    },
    switchovers(item) {
      if (item.router == '') {
        this.$toast('暂未开放');
      } else if (item.router == 'superior') {
        this.getUserInfo();
        this.show = true;
      } else {
        this.$router.push({ name: item.router });
      }

      // this.$Router.pushPage({
      //   name: route
      // })
    },
    reckoningClick(name) {
      this.$router.push({ name: name });
    },
    getUserInfo() {
      homeApi.getUserInfo({}).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.userinfo = res.data;
        }
      });
    },
    // getAccountInfo() {
    //   homeApi.getUserInfo({ address: this.account, inviteCode: this.inviteCode }).then(res => {
    //     console.log(res);
    //     if (res.code == 200) {
    //       this.indexinfo = res.data;
    //     }
    //   });
    // },
    copyAccount() {
      console.log(11);
      if (this.account) {
        var clipboard = new Clipboard('.copys');
        clipboard.on('success', e => {
          this.$toast('复制成功');
          clipboard.destroy(); // 释放内存
        });
        clipboard.on('error', e => {
          this.$toast('复制失败,浏览器不支持');
          clipboard.destroy(); // 释放内存
        });
      }
    },
    // 提现手续费
    withdrawFee() {
      homeApi.withdrawFee().then(res => {
        console.log(res);
        if (res.code == 200) {
          this.feeObj = res.data;
          // this.withdrawAmount = Math.floor(res.data.usdt * 100) / 100;
        }
      });
    },
    withdrawSubmit() {
      let web3 = new Web3(window.web3.currentProvider);
      web3.eth.personal.sign(web3.utils.fromUtf8('withdraw'), this.account, (err, res) => {
        let params = {
          amount: this.withdrawAmount,
          sign: res,
        };
        homeApi.withdrawCash(params).then(res => {
          if (res.code == 200) {
            Notify({ type: 'primary', message: res.msg });
            this.withdrawShow = false;
            this.init();
          } else {
            Notify({ message: res.msg });
          }
        });
      });
    },
  },
  computed: {
    ...mapState(['account']),
    getAccount() {
      if (this.account) {
        return '0x...' + this.account.substring(this.account.length - 16, this.account.length);
      } else {
        return '';
      }
    },
  },
  watch: {
    account() {
      this.init();
    },
    withdrawAmount(e) {
      // this.receiptAmount = 0;
      this.receiptAmount = Number(e) - Number(e) * Number(this.feeObj.withdraw_fee_rate);
    },
  },
  components: {
    Superior,
  },
};
</script>

<style lang="less" scoped>
.orders {
  .banner {
    display: flex;
    justify-content: space-between;
    background: #238be8;
    padding: 40px 20px 80px;
    .avatar {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #fff;
      .fd_img {
        width: 60px;
        height: 51px;
        margin-right: 14px;
        background: #ffffff;
        border-radius: 5px;
      }
    }
    .grade {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #fff;
      .grade_img {
        display: block;
        width: 24px;
        height: 24px;
      }
    }
  }
  .content {
    padding: 0 20px;
    margin-top: -40px;
    .balance_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .balance_li {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        width: 48%;
        font-size: 14px;
        background: #fff;
        border-radius: 16px;
        padding: 14px;
        .left {
          display: flex;
          flex-direction: column;
        }
        .btn {
          color: #ffffff;
          font-size: 12px;
          padding: 6px 12px;
          border-radius: 10px;
          background-color: #238be8;
        }
        .amount {
          font-size: 16px;
          font-weight: bold;
          margin-top: 6px;
        }
      }
    }
    .list {
      background: #fff;
      border-radius: 16px;
      padding: 0 20px 0 10px;
      margin-top: 20px;
      .li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        color: #238be8;
        padding: 6px 0;
        .li_left {
          display: flex;
          align-items: center;
          .li_img {
            display: block;
            width: 36px;
            height: 36px;
          }
        }
      }
    }
  }

  .withdrawPopup {
    padding: 20px 30px;
    color: #666666;
    font-size: 14px;
    .title {
      display: flex;
      justify-content: center;
      padding-bottom: 20px;
      font-weight: bold;
      font-size: 16px;
      color: #000000;
    }

    .tip {
      font-size: 12px;
      display: flex;
      flex-direction: column;
    }
    .btn {
      color: #fff;
      padding: 16px 0;
      text-align: center;
      line-height: 50%;
      border-radius: 20px;
      margin-top: 18px;
      background-color: #238be8;
    }
    .close—icon {
      position: absolute;
      bottom: -40px;
      left: 45%;
    }
  }
}
/deep/ .van-popup {
  overflow-y: visible;
}

/deep/ .van-field {
  background-color: #f3f9fe;
  border-radius: 8px;
  margin: 6px 0;
}
/deep/ .van-field__body {
  padding: 10px 10px;
}
.fire_img {
  width: 28px;
  height: 28px;
}
.total_box {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #fff;
  background: rgba(#238be8, 0.7);
  border-radius: 12px;
  padding: 20px;
  margin-top: 20px;
  .total {
    font-size: 26px;
    font-weight: bold;
    margin-top: 14px;
  }
}
</style>