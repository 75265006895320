import { render, staticRenderFns } from "./superiorPopup.vue?vue&type=template&id=645a79c4&scoped=true&"
import script from "./superiorPopup.vue?vue&type=script&lang=js&"
export * from "./superiorPopup.vue?vue&type=script&lang=js&"
import style0 from "./superiorPopup.vue?vue&type=style&index=0&id=645a79c4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "645a79c4",
  null
  
)

export default component.exports