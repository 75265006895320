import request from '../utils/request';

export default {
    //充币配置
    getWalletGetaddress(data, address) {
        return request.request({
            url: '/wallet/get-address',
            method: "post",
            headers: {
                'Lang': localStorage.getItem('lang') || '',
                Authorization: address
            },
            data
        })
    },

    //钱包信息
    getWalletList(address) {
        return request.request({
            url: '/wallet/list',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang') || '',
                Authorization: address
            },
        })
    },

    //单个钱包信息
    getWalletInfo(data, address) {
        return request.request({
            url: '/wallet/info',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang') || '',
                Authorization: address
            },
            params: data,
        })
    },

    //行为条件
    walletBehaviors(address) {
        return request.request({
            url: '/wallet/behaviors',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang') || '',
                Authorization: address
            },
        })
    },

    //单个钱包信息
    getWalletRecordList(data, address) {
        return request.request({
            url: '/wallet/record/list',
            method: "get",
            headers: {
                'Lang': localStorage.getItem('lang') || '',
                Authorization: address
            },
            params: data,
        })
    },

    //标记入账
    entryMark(data, address) {
        return request.request({
            url: '/wallet/entry-mark',
            method: "post",
            headers: {
                'Lang': localStorage.getItem('lang') || '',
                Authorization: address
            },
            data: data,
        })
    }
}