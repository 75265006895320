<template>
  <div class="miner">
    <div class="miner_top">
      <div class="team_box">
        <div class="team_universal" style="margin-bottom: 10px">
          <span>团队总算力</span>
          <span class="universal_amount" style="font-size: 20px">{{ Math.floor(dataObj.team_power * 100) / 100 || 0 }}</span>
        </div>
        <div class="team_list">
          <div class="team_universal">
            <span>团队人数</span>
            <span class="universal_amount">{{ dataObj.team_num || 0 }}</span>
          </div>
          <div class="team_universal">
            <span>直推人数</span>
            <span class="universal_amount">{{ dataObj.direct_num || 0 }}</span>
          </div>
        </div>
      </div>

      <div class="yesterday">
        <img src="@/assets/image/income.png" class="yesterday_img" />
        <span>昨日收益</span>
      </div>
      <div class="earnings">
        <div class="earnings_li">
          <span class="li_amount">{{ Math.floor(yesterday_profit.power * 100) / 100 || 0 }}</span>
          <span>算力</span>
        </div>
        <div class="earnings_li" style="border-left: 1px solid #cccccc7a">
          <span class="li_amount">{{ Math.floor(yesterday_profit.usdt * 100) / 100 || 0 }}</span>
          <span>USDT</span>
        </div>
        <div class="earnings_li" style="border-left: 1px solid #cccccc7a">
          <span class="li_amount">{{ Math.floor(yesterday_profit.token * 100) / 100 || 0 }}</span>
          <span>平台币</span>
        </div>
      </div>
    </div>

    <div class="gain">
      <div class="gain_title">
        <span @click="gainClick(1)" :class="gainIndex == 1 ? 'special' : ''">算力收益</span>
        <span @click="gainClick(2)" :class="gainIndex == 2 ? 'special' : ''">USDT收益</span>
        <span @click="gainClick(3)" :class="gainIndex == 3 ? 'special' : ''">平台币收益</span>
        <span @click="gainClick(4)" :class="gainIndex == 4 ? 'special' : ''">直推列表</span>
      </div>

      <div v-if="gainIndex != 4">
        <div class="list_title">
          <span>收益类型</span>
          <span style="text-align: center">数量</span>
          <span style="text-align: right">日期</span>
        </div>
        <div class="bare" v-if="total < 1">
          <span>暂无数据</span>
        </div>
        <div class="list" v-else>
          <!-- <div v-for="(item, index) of 10" :key="index" class="li">
          <span>算力奖励</span>
          <span style="text-align: center">+256.2623</span>
          <span style="text-align: right">2023-05-07</span>
        </div> -->
          <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
            <div v-for="(item, index) in list" :key="index" class="li">
              <span>{{ typeFormatFn(item.action) }}</span>
              <span style="text-align: center">{{ Math.floor(item.change_amount * 100) / 100 || 0 }}</span>
              <span style="text-align: right">{{ dateFormatFn(item.create_time) }}</span>
            </div>
          </van-list>
        </div>
      </div>
      <div v-else>
        <div class="list_title">
          <span>钱包地址</span>
          <span style="text-align: center">租赁算力</span>
          <span style="text-align: right">注册时间</span>
        </div>
        <div class="bare" v-if="total < 1">
          <span>暂无数据</span>
        </div>
        <div class="list" v-else>
          <!-- <div v-for="(item, index) of 10" :key="index" class="li">
          <span>算力奖励</span>
          <span style="text-align: center">+256.2623</span>
          <span style="text-align: right">2023-05-07</span>
        </div> -->
          <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
            <div v-for="(item, index) in list" :key="index" class="li">
              <span>{{ item.address }}</span>
              <span style="text-align: center">{{ item.power || 0 }}</span>
              <span style="text-align: right">{{ dateFormatFn(item.create_time) }}</span>
            </div>
          </van-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Web3 from 'web3';
import Clipboard from 'clipboard';
import { homeApi } from '@/api/index';
import { dateFormat, typemat } from '@/utils/formatTool.js';
export default {
  data() {
    return {
      gainIndex: 1,
      dataObj: {
        team_power: undefined,
        team_num: undefined,
        direct_num: undefined,
      },
      page: 0,
      loading: false,
      finished: false,
      total: 0,
      list: [],
      yesterday_profit: {
        power: undefined,
        usdt: undefined,
        token: undefined,
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.account) {
        this.teamInfo();
        this.onLoad();
      }
    },
    dateFormatFn(date) {
      return dateFormat(new Date(date * 1000), 'yyyy-MM-dd hh:mm:ss');
    },
    typeFormatFn(type) {
      return typemat(type);
    },
    gainClick(i) {
      this.gainIndex = i;
      this.page = 1;
      this.list = [];
      this.profitList();
    },
    // 复制
    copy() {
      var clipboard = new Clipboard('.copys');
      clipboard.on('success', e => {
        this.$toast(this.$t('lang.swap222'));
        clipboard.destroy(); // 释放内存
      });
      clipboard.on('error', e => {
        this.$toast(this.$t('lang.swap223'));
        clipboard.destroy(); // 释放内存
      });
    },
    teamInfo() {
      homeApi.teamInfo().then(res => {
        console.log(res.data, 'kdkjfh');
        this.dataObj = res.data;
        this.yesterday_profit = res.data.yesterday_profit;
      });
    },
    profitList() {
      let params = {
        page: this.page,
        query: {
          type: this.gainIndex,
        },
      };
      this.loading = true;
      this.finished = false;
      homeApi.profitList(params).then(res => {
        if (res.code == 200) {
          let rows = res.data.rows;
          // 如果返回的数组是空或数组长度是0
          if (rows == null || rows.length === 0) {
            this.loading = false; // 关闭加载状态
            this.finished = true; // 加载结束
            return;
          }
          this.loading = false; // 关闭加载状态
          this.total = res.data.total; //数据总条数
          this.list = this.list.concat(rows); //合并数组
          console.log(this.list);

          // 如果合并之后的数组长度大于返回的数据总条数
          if (this.list.length >= this.total) {
            this.finished = true; // 加载结束
          }
        } else {
          this.total = 0;
          this.loading = false; // 关闭加载状态
          this.finished = true; // 加载结束
        }
      });
    },
    onLoad() {
      this.page += 1;
      this.profitList();
    },
  },
  computed: {
    ...mapState(['account']),
  },
  watch: {
    account() {
      this.init();
    },
  },
  components: {},
};
</script>

<style lang="less" scoped>
.miner {
  .miner_top {
    background: #feffff url('~@/assets/image/mining_bg.png') no-repeat;
    background-size: 100%;
    padding: 100px 20px 20px;
  }
  .team_box {
    position: relative;
    background: #fff;
    box-shadow: 0 0 4px rgba(77, 146, 223, 0.16);
    border-radius: 14px;
    padding: 20px;
    margin-bottom: 20px;
    .team_universal {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      color: #999;
      .universal_amount {
        font-size: 16px;
        font-weight: bold;
        color: #000;
        margin-top: 6px;
      }
    }
    .team_list {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .yesterday {
    display: flex;
    align-items: center;
    color: #a5a5a5;
    .yesterday_img {
      display: block;
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
  }
  .earnings {
    display: flex;
    align-items: center;
    padding: 20px 0;
    .earnings_li {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 32%;
      font-size: 14px;
      .li_amount {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .gain {
    background: #fff;
    margin-top: 10px;
    padding-bottom: 20px;
    .gain_title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: rgba(102, 102, 102, 1);
      padding: 20px 20px;
      span {
        border-left: 3px solid #238ce800;
        padding-left: 6px;
      }
      .special {
        color: #238ce8;
        border-left: 3px solid #238ce8;
      }
    }
    .list_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      color: #666666;
      padding: 0 30px;
      margin-bottom: 10px;
      span {
        width: 30%;
      }
    }
    .li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      color: #666666;
      padding: 0 30px;
      margin-top: 10px;
      span {
        width: 30%;
      }
    }
    .bare {
      text-align: center;
      font-size: 14px;
      padding: 14px;
      color: #888;
    }
  }
}
</style>