import axios from "axios";
import { Toast } from "vant";
import store from "../store";
const service = axios.create({
    // baseURL: 'http://192.168.6.207:80/api/',
    // baseURL: 'https://dapp-api.uaddev.tk/api',
    baseURL: 'https://api.aleohsbc.vip/api', // 正式
});

console.log(localStorage.getItem("token__" + localStorage.getItem("account")), '请求获取到的token');
service.interceptors.request.use((config) => {
    config.data = config.data;
    config.headers.token = localStorage.getItem("token__" + localStorage.getItem("account"))
    return config
});

// ajax响应拦截器;
service.interceptors.response.use(
    (res) => {
        // 获取后端返回的数据与状态码;
        // 状态码位200时, 则无异常;
        console.log(res, "res111");
        if (res.data.code === 200) {
            return res.data;
        } else if (res.data.code === 14999) {  //token失效，删除登录信息
            console.log(222);
            localStorage.clear()
            //在这个地方做个 操作 去调用store里面的方法或复制过来
            store.dispatch('setWebProvider');

            Toast({ message: `${res.data.msg}` })
            return Promise.reject(res.data);
        } else if (res.status === 500 || 5101) {
            // 500 网络错误(服务端错误)
            Toast({ message: `${res.data.msg}` || "Something went wrong, please try again later", className: 'toastLevel' });
            return Promise.reject(res.data);
        } else {                      // 状态码不为200时, 则视情况处理;
            return Promise.reject(res.data);
        }
    },
    (error) => {
        console.log(error, "error");
        if (error.message.indexOf("status code 500") > -1) {
            Toast("Server error, please try again later");
        }
        if (error.message.indexOf("timeout") > -1) {
            Toast("Connection timed out");
        }
        if (error && error.response) {
            // 登录过期

            if (error.response.code === 401) {
                // 清除登录
            } else if (error.response.code === 14999) {  //token失效，删除登录信息
                console.log(111);
                //在这个地方做个 操作 去调用store里面的方法或复制过来
                // store.dispatch('setWebProvider');
                Toast({ message: `${error.response.data.message}` })
            } else {
                Toast(`${error.response.code}-${error.response.data.message}`);
            }
        }
        return Promise.reject(error);
    }
);
export default service;