(function (window, document) {
	function resize () {
		var ww = window.innerWidth;
		//设计稿以750为宽度，我们把页面宽度设计为 7.5rem
		//baseSize就是postcss.config.js 里面设置的 rootValue 值，这个是设计稿中1rem转换成px的大小
		const baseSize = 16;
		//实际设备页面宽度和设计稿的比值
		// if(ww>750){

		// }
		if (ww < 600) {
			let scale = ww / 375;
			document.documentElement.style.fontSize = (baseSize * scale) + 'px';
		} else if (ww > 600 && ww < 900) {
			let scale = ww / 750;
			document.documentElement.style.fontSize = (baseSize * scale) + 'px';
		} else if (ww > 900 &&ww < 1250) {
			let scale = ww / 1000;
			document.documentElement.style.fontSize = (baseSize * scale) + 'px';
		}	else if (ww > 1250 && ww < 1500) {
			let scale = ww / 1250;
			document.documentElement.style.fontSize = (baseSize * scale) + 'px';
		}else if ( ww > 1500) {
			let scale = ww / 1600;
			document.documentElement.style.fontSize = (baseSize * scale) + 'px';
		}
		//计算实际的rem值并赋予给html的font-size
	}
	if (document.readyState !== 'loading') {
		resize();
	} else {
		document.addEventListener('DOMContentLoaded', resize);
	}
	window.addEventListener('resize', resize);
})(window, document);