<template>
  <div class="swiperBox">
    <!-- <img class="imgLeft" @click="clickLeft" src="../../../assets/img/左.png" alt="">
        <img class="imgRight" @click="clickRight" src="../../../assets/img/右.png" alt=""> -->
    <div id="swiper">
      <div class="imgBox">
        <div class="imgDiv"
             v-for="(item,index) of imgList"
             :key="index">
          <img :src="item"
               class="img" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BaseSwiper',
  props: {
    speed: Number,
    direction: String,
  },
  data () {
    return {
      imgList: [
        require('@/assets/img/basicSwiper/1.png'),
        require('@/assets/img/basicSwiper/2.png'),
        require('@/assets/img/basicSwiper/3.png'),
        require('@/assets/img/basicSwiper/4.png'),
        require('@/assets/img/basicSwiper/5.png'),
        require('@/assets/img/basicSwiper/6.png'),
        require('@/assets/img/basicSwiper/7.png'),
        require('@/assets/img/basicSwiper/8.png'),
        require('@/assets/img/basicSwiper/9.png'),
        require('@/assets/img/basicSwiper/10.png'),
        require('@/assets/img/basicSwiper/12.png'),
        // require('@/assets/img/basicSwiper/13.png'),

      ],
      timer: null,
      theSpeed: this.speed,
      imgWidth: 260,
      theDirection: this.direction,
    }
  },
  methods: {
    clickLeft () {
      this.theDirection = 'left';
    },
    clickRight () {
      this.theDirection = 'right';
    },
  },
  mounted () {
    let imgBox = document.getElementsByClassName('imgBox')[0];
    //将imgBox下的图片进行拼接 循环展示图片
    imgBox.innerHTML += imgBox.innerHTML;
    let imgDiv = document.getElementsByClassName('imgDiv');
    imgBox.style.width = imgDiv.length * this.imgWidth + 'px';//设置div的宽度使图片可以放下
    let self = this;
    console.log(imgBox.offsetWidth, imgBox.style.width)
    function autoScroll () {
      if (imgBox.offsetLeft < -(imgBox.offsetWidth / 2)) {//提前更新left值，实现循环展示
        imgBox.style.left = 0;
      }
      if (imgBox.offsetLeft > 0) {//向右滚动 提前更新left值，实现循环展示
        imgBox.style.left = -(imgBox.offsetWidth / 2) + 'px';
      }
      if (self.theDirection == 'left') { //向左滚动,值为负
        self.theSpeed = -Math.abs(self.theSpeed)
      } else { //向右滚动
        self.theSpeed = Math.abs(self.theSpeed)
      }
      // 求出总的left值，等于left值加上移动的速度（px值）
      imgBox.style.left = imgBox.offsetLeft + self.theSpeed + 'px';
    }
    this.timer = setInterval(autoScroll, 30);//全局变量 ，保存返回的定时器
  },
  beforeDestroy () {
    clearInterval(this.timer);
    this.timer = null;
  }
}
</script>
<style scoped lang='less'>
.swiperBox {
  height: 60px;
  width: 100%;
  position: relative;
  .imgLeft {
    left: 0;
    top: 40%;
  }
  .imgLeft,
  .imgRight {
    width: 27px;
    height: 38px;
    position: absolute;
    cursor: pointer;
  }
  .imgRight {
    right: 0;
    top: 40%;
  }
  .directionIcon:hover {
    opacity: 1;
  }
  #swiper {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    .imgBox {
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      overflow: hidden;
      display: flex;
      .imgDiv {
        // width: 100%;
        margin-left: 15px;
        background: #0e1b32;
        display: flex;
        justify-content: center;
        align-items: center;
        .img {
          // height: 100%;
          // width: 280px;
          width: 120px;
          height: 40px;
        }
      }
    }
  }
}
</style>