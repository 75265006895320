<template>
  <div>
    <div class="head">
      <img @click="back" src="@/assets/image/arrow.png" class="arrow_img" />
      <span>提现</span>
    </div>
    <div class="withdraw">
      <div class="balance_box">
        <span>可用余额（USDT）</span>
        <span class="balance">0.00</span>
      </div>
      <div class="extract_box">
        <div class="caption">
          <span>提现金额</span>
        </div>
        <div class="field_box">
          <van-field v-model="amount" class="field" placeholder="请输入提现额度" />
          <span style="font-weight: bold">0.00</span>
          <span class="all">全部</span>
        </div>
        <div class="text">
          <span>温馨提示</span>
          <span>1.每次提现最小额度限制为：100USDT。</span>
          <span>2.每人每天只能提交一次提现申请。</span>
          <span>3.每次提现扣除手续费1%。</span>
        </div>
      </div>
      <div class="button">
        <van-button class="submit_button" type="primary" @click="withdrawCash">提交</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { homeApi } from '@/api/index';
import { Notify } from 'vant';
import Web3 from 'web3';
export default {
  data() {
    return {
      amount: '', //提现金额
    };
  },
  methods: {
    //返回上一页
    back() {
      this.$router.back();
    },
    withdrawCash() {
      let web3 = new Web3(window.web3.currentProvider);
      web3.eth.personal.sign(web3.utils.fromUtf8('withdraw'), account, (err, res) => {
        let params = {
          amount: this.amount,
          sign: res,
        };
        homeApi.withdrawCash(params).then(res => {
          if (res.code == 200) {
            Notify({ type: 'primary', message: res.msg });
          } else {
            Notify({ message: res.msg });
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.head {
  position: relative;
  display: flex;
  justify-content: center;
  color: #000;
  background: #fff;
  padding: 20px 0 10px;
  .arrow_img {
    position: absolute;
    left: 20px;
    top: 46%;
    width: 10px;
    height: 16px;
    // transform: translate(0, -50%);
  }
}
.withdraw {
  padding: 20px;
  .balance_box {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #fff;
    background: #238be8;
    border-radius: 12px;
    padding: 20px;
    .balance {
      font-size: 26px;
      font-weight: bold;
      margin-top: 14px;
    }
  }
  .extract_box {
    font-size: 12px;
    color: rgba(102, 102, 102, 1);
    background: #ffffff;
    border-radius: 10px;
    padding: 16px 24px;
    margin-top: 16px;
    .caption {
      font-size: 14px;
    }
    .field_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      color: #000;
      border-bottom: 1px solid #eeeeee;
      padding: 6px 0;
      margin: 20px 0 16px;
      .field {
        flex: 1;
        font-size: 16px;
      }
      .all {
        font-size: 12px;
        color: #fff;
        background: #238be8;
        border-radius: 4px;
        padding: 4px 8px;
        margin-left: 24px;
      }
    }
    .text {
      display: flex;
      flex-direction: column;
    }
  }
  .button {
    display: flex;
    justify-content: center;
    .submit_button {
      height: auto;
      background: #238be8;
      border: 0;
      border-radius: 8px;
      padding: 10px 100px;
      margin-top: 44px;
    }
  }
}
</style>