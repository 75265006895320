
import $store from "@/store";

// 遍历对象拼接key、value值
export const eachParam = obj => {
  // 参数为空
  if (!obj || !Object.keys(obj).length) {
    return "";
  }
  let str = "";
  Object.keys(obj).forEach((key, index) => {
    str += `${key}=${obj[key]}&`;
  });
  str = str.slice(0, -1);
  return str;
};

// 获取路由参数方法
export const getCurPage = query => {
  // eslint-disable-next-line
  let pages = getCurrentPages();
  let curPage = pages[pages.length - 1];
  // 路由还未加载
  if (!curPage) {
    return false;
  }
  // 微信小程序有时候options为undefined 特殊处理一下
  if (!curPage.options) {
    curPage.options = {};
  }
  // 手动添加query字段

  // #ifdef MP-ALIPAY
  for (var key in query) {
    // 支付宝要手动加上参数
    curPage.query[key] = query[key];
  }
  // #endif
  curPage.query = curPage.options;
  return curPage;
};

const handleRoute = (obj, delay) => {
  // 路由调整时obj.query参数对象是否传递 未使用置空对象
  if (!obj.query || !Object.keys(obj.query).length) {
    obj.query = {};
  }
  return obj;
};

const reLaunchDelay = (obj, i = 0) => {
  if (i >= 10) {
    return false;
  }
  setTimeout(() => {
    // uni.reLaunch({
    //   url: `${obj.path}?${eachParam(obj.query)}`,
    //   fail: err => {
    //     console.log(err);
    //     reLaunchDelay(obj, i++);
    //   }
    // });
  }, 200);
};
// push navigateTo
export const push = (obj, delay = false) => {
  // 如果当前处在登录页 则无法再跳转进入登录页
  const data = handleRoute(obj, delay);
  if (data) {
    // uni.navigateTo({
    //   url: `${data.path}?${eachParam(data.query)}`
    // });
  }
};

// replace navigateTo
export const replace = (obj, delay = false) => {
  const data = handleRoute(obj, delay);
  if (data) {
    // uni.redirectTo({
    //   url: `${data.path}?${eachParam(data.query)}`
    // });
  }
};

// switchTab navigateTo
export const switchPage = (obj, delay = false) => {
  const data = handleRoute(obj, delay);
  if (data) {
    // uni.switchTab({
    //   url: `${data.path}?${eachParam(data.query)}`
    // });
  }
};
