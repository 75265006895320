<template>
  <div class="bulletin">
    <div class="head">
      <img @click="back" src="@/assets/image/arrow.png" class="arrow_img" />
      <span>公告</span>
    </div>
    <div class="bare" v-if="total < 1">
      <span>暂无数据</span>
    </div>
    <div v-else>
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" class="list_box">
        <!-- <van-cell v-for="(item,index) in list" :key="index" :title="item.title" /> -->
        <div v-for="(item, index) in list" :key="index" @click="jump(item)" class="li">
          <div class="li_title">
            <span class="title">{{ item.title }}</span>
            <span>{{ item.create_time }}</span>
          </div>
          <div class="li_text">
            <span>{{ item.body }}</span>
          </div>
        </div>
      </van-list>
    </div>
    <!-- <div class="list_box">
      <div v-for="(item, index) of list" :key="index" @click="jump" class="li">
        <div class="li_title">
          <span class="title">{{ item.title }}</span>
          <span>{{ item.create_time }}</span>
        </div>
        <div class="li_text">
          <span>{{ item.body }}</span>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { homeApi } from '@/api/index';
export default {
  data() {
    return {
      list: [],
      page: 1,
      loading: false,
      finished: false,
      total: 1,
    };
  },
  mounted() {
    this.getAnnouncement();
  },
  methods: {
    //返回上一页
    back() {
      this.$router.back();
    },
    jump(item) {
      // console.log('789');
      // this.$Router.pushPage({
      //   name: 'buDetails'
      // })
      this.$router.push({ name: 'buDetails',query:{...item} });
    },
    getAnnouncement() {
      let params = {
        page: this.page,
        query: {},
      };
      homeApi.getAnnouncement(params).then(res => {
        if (res.code == 200) {
          let rows = res.data.rows;
          // 如果返回的数组是空或数组长度是0
          if (rows == null || rows.length === 0) {
            this.loading = false; // 关闭加载状态
            this.finished = true; // 加载结束
            return;
          }
          this.loading = false; // 关闭加载状态
          this.total = res.data.total; //数据总条数
          this.list = this.list.concat(rows); //合并数组
          console.log(this.list);

          // 如果合并之后的数组长度大于返回的数据总条数
          if (this.list.length >= this.total) {
            this.finished = true; // 加载结束
          }
        } else {
          this.total = 0;
          this.loading = false; // 关闭加载状态
          this.finished = true; // 加载结束
        }
      });
    },
    onLoad() {
      this.page += 1;
      this.getAnnouncement();
    },
  },
};
</script>

<style lang="less" scoped>
.bulletin {
  .head {
    position: relative;
    display: flex;
    justify-content: center;
    color: #000;
    background: #fff;
    padding: 20px 0 10px;
    .arrow_img {
      position: absolute;
      left: 20px;
      top: 46%;
      width: 10px;
      height: 16px;
      // transform: translate(0, -50%);
    }
  }
  .list_box {
    padding: 16px;
    .li {
      padding: 16px 0;
      border-bottom: 1px solid #dadcdd;

      .li_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #666;
        font-size: 12px;
        .title {
          width: 70%;
          font-size: 16px;
          font-weight: bold;
          color: #000;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .li_text {
        font-size: 12px;
        color: #333;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        margin-top: 10px;
      }
    }
  }
  .bare {
    text-align: center;
    font-size: 14px;
    padding: 40px 14px;
    color: #888;
  }
}
</style>