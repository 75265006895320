<template>
  <div class="invite">
    <div class="head">
      <img @click="back"
           src="@/assets/image/arrow.png"
           class="arrow_img">
      <span>邀请</span>
    </div>
    <div class="content_box">
      <div class="block_title">
        <span class="block_line_left"></span>
        <span class="block_text">邀请链接</span>
        <span class="block_line_right"></span>
      </div>

      <div class="invite_box">
        <div class="code">
          <img src="@/assets/image/logo.png"
               class="code_img">
        </div>
        <div class="invite_address">
          <span>{{inviteLink}}</span>
        </div>
        <div class="replicate">
          <span class="copy copys"
                @click="copy"
                :data-clipboard-text="this.inviteLink">复制链接</span>
        </div>
        <div class="hint">
          <div>
            <span>复制链接发给您的朋友，获取更高的收益！</span>
          </div>
          <!-- <div class="describe">
            <img src="@/assets/image/describes.png"
                 class="describes_img">
            <span>级别描述</span>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Clipboard from 'clipboard';
import { homeApi } from '@/api/index';
export default {
  data () {
    return {
      inviteLink: ''
    }
  },
  mounted(){
    this.getUserInfo()
  },
  methods: {
    //返回上一页
    back () {
      this.$router.back()
    },
    copy () {
      if (this.inviteLink) {
        var clipboard = new Clipboard('.copys');
        clipboard.on('success', e => {
          this.$toast('复制成功');
          clipboard.destroy(); // 释放内存
        });
        clipboard.on('error', e => {
          this.$toast('复制失败,浏览器不支持');
          clipboard.destroy(); // 释放内存
        });
      }
    },
    getUserInfo(){
      homeApi.getUserInfo().then(res=>{
        if(res.code == 200){
          this.inviteLink = res.data.invite_link
        }
      })
    }

  }
}
</script>

<style lang="less" scoped>
.invite {
  .head {
    position: relative;
    display: flex;
    justify-content: center;
    color: #000;
    background: #fff;
    padding: 20px 0 10px;
    .arrow_img {
      position: absolute;
      left: 20px;
      top: 46%;
      width: 10px;
      height: 16px;
      // transform: translate(0, -50%);
    }
  }
  .content_box {
    background: #f3f9fe url('~@/assets/image/invite_bg.png') no-repeat;
    background-size: 100%;
    padding: 20px;
    .block_title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px 0;
      .block_line_left {
        flex: 1;
        height: 2px;
        background: linear-gradient(90deg, hsla(0, 0%, 85%, 0), #fff);
      }
      .block_text {
        color: #fff;
        margin: 0 14px;
      }
      .block_line_right {
        flex: 1;
        height: 2px;
        background: linear-gradient(90deg, #fff, hsla(0, 0%, 85%, 0));
      }
    }
    .invite_box {
      background: #fff;
      border-radius: 10px;
      padding: 40px 20px;
      .code {
        display: flex;
        justify-content: center;
        .code_img {
          display: block;
          width: 140px;
          height: 117px;
        }
      }
      .invite_address {
        font-size: 14px;
        color: #333;
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        white-space: nowrap; //溢出不换行
        margin: 60px 0 30px;
      }
      .replicate {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        .copy {
          color: #fff;
          // line-height: 44px;
          background: #238be8;
          border-radius: 10px;
          text-align: center;
          padding: 10px 40px;
        }
      }
      .hint {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        background: #f3f9fe;
        border-radius: 10px;
        padding: 20px 20px 40px;
        .describe {
          display: flex;
          align-items: center;
          // display: inline-block;
          background: #ffffff;
          padding: 10px 10px;
          margin-top: 20px;
          .describes_img {
            display: block;
            width: 20px;
            height: 20px;
            margin-right: 6px;
          }
        }
      }
    }
  }
}
</style>