<template>
  <div class="father">
    <div class="head">
      <img @click="back" src="@/assets/image/arrow.png" class="arrow_img" />
      <span>平台介绍</span>
    </div>
    <div class="details">
      <div class="introduce_top">
        <img src="@/assets/image/introduce_img.png" class="introduce_img" />
        <div>
          <!-- <div class="intro">平台或公司相关介绍</div> -->
          <div>Aleo是第一个使用零知识证明解决隐私问题、同时保证可编程特性的公链。</div>
        </div>
      </div>
      <div style="text-indent: 2em">
        <p>Aleo提供的隐私保护包括隐藏参与者、金额、智能合约等交互细节，而且Aleo还将智能合约执行转移到链下，支持各种Dapp，保证其可拓展性（每秒数千笔交易)。</p>
        <p>
          ALEO于2016年形成初步构思，2019年成立实验室、于2021年4月20日由A16Z领投2800万美金，2022年B轮融资有三星、A16Z、老虎基金、coinbase、软银意愿等19家机构高达2亿美金注入。是零知识证明行业有史以来最大的一笔投资，ALEO现在估值已到达14.5亿美金。ALEO是采取学分制的，通过我们布置服务器算力工作获得区块学分奖励，学分是ALEO运行的唯一燃料，测试有奖励，主网算力产出，现在采取5个学分兑换1个ALEO的TOKEN.我们错过了2009年涨了1380万倍的比特，错过了14年涨了15580倍的以太，错过了21年涨了8705倍的狗狗，今天ALEO是整个区块链ZK领域和隐私保护的赛道龙头，主网即将上线，属于我们的机会已然来临。。。。。
        </p>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
    //返回上一页
    back() {
      this.$router.back();
    },
  },
};
</script>
  
<style lang="less" scoped>
.father{
  // height: calc(100vh - 52px);
  // background: #fff;
}
.head {
  position: relative;
  display: flex;
  justify-content: center;
  color: #000;
  background: #fff;
  padding: 20px 0 10px;
  .arrow_img {
    position: absolute;
    left: 20px;
    top: 46%;
    width: 10px;
    height: 16px;
    // transform: translate(0, -50%);
  }
}
.details {
  padding: 20px;

  .title {
    font-size: 16px;
    color: #333;
    font-weight: bold;
  }
  .time {
    font-size: 14px;
    color: #666;
    margin-top: 10px;
  }
  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #333;
    margin: 20px 0;
  }
  .introduce_top {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    .introduce_img {
      display: block;
      width: 82px;
      height: 82px;
      margin-right: 10px;
    }
    .intro {
      font-size: 14px;
      font-weight: bold;
      color: #238be8;
      margin-bottom: 10px;
    }
  }
}
</style>