import request from '../utils/request';

export default {
  //公告列表
  getAnnouncement(data) {
    return request.request({
      url: '/announcement/list',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //租赁算力
  rental(data) {
    return request.request({
      url: '/home/rental',
      method: "post",
      headers: {
        // Authorization: address,        
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //首页的各项数据
  getIndexAllInfo(data) {
    return request.request({
      url: '/home/all',
      method: "post",
      headers: {
        // Authorization: address,        
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //用户信息
  getUserInfo(data) {
    return request.request({
      url: '/user/userInfo',
      method: "post",
      headers: {
        // Authorization: address
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //注册
  register(data) {
    return request.request({
      url: '/member/register',
      method: "post",
      headers: {
        // Authorization: address,
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //用户信息/登录
  auth(data) {
    return request.request({
      url: '/member/auth',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //提现记录
  withdrawList(data) {
    return request.request({
      url: '/offline/withdrawList',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //算力明细列表
  powerList(data) {
    return request.request({
      url: '/ledger/powerList',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //USDT明细列表
  usdtList(data) {
    return request.request({
      url: '/ledger/usdtList',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //虚拟币明细列表
  tokenList(data) {
    return request.request({
      url: '/ledger/tokenList',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //团队数据
  teamInfo(data) {
    return request.request({
      url: '/team/all',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //提现
  withdrawCash(data) {
    return request.request({
      url: '/offline/withdrawCash',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //租赁比例
  prepareRental(data) {
    return request.request({
      url: '/home/prepareRental',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //账变类型
  actionGet(data) {
    return request.request({
      url: '/ledger/actionGet',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //团队收益列表
  profitList(data) {
    return request.request({
      url: '/team/profitList',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //充值记录
  rechargeList(data) {
    return request.request({
      url: '/offline/rechargeList',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //提现手续费
  withdrawFee(data) {
    return request.request({
      url: '/offline/withdrawFee',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
}